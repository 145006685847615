import { ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { GrassCalendarState } from '@/store/modules/grasscalendar/grassCalendarState';
import axios from 'axios';
import GrassCalendarField from '@/core/interfaces/GrassCalendarField';
import { GrassCalendarAction } from '@/core/interfaces/GrassCalendarAction';
import { actionId, actionType } from '@/store/modules/grasscalendar/mutations';

const actions: ActionTree<GrassCalendarState, RootState> = {
    fetchFields: async(context) => {
        const fields = (await axios.get(`/grass-calendar/farms/${context.rootState.farmId}/fields`)).data;

        context.commit('setGrassCalendarFields', fields);
    },

    saveField: async(context, payload: Partial<GrassCalendarField>) => {
        await axios.post(`/grass-calendar/farms/${context.rootState.farmId}/fields`, payload);

        return context.dispatch('fetchFields');
    },

    updateField: async(context, payload: GrassCalendarField) => {
        const response = (await axios.put(`/grass-calendar/farms/${context.rootState.farmId}/fields/${payload.id}`, payload)).data;

        context.commit('updateGrassCalendarField', response);
    },

    deleteField: async(context, payload: number) => {
        await axios.delete(`/grass-calendar/farms/${context.rootState.farmId}/fields/${payload}`);

        context.commit('removeGrassCalendarField', payload);
    },

    fetchActions: async(context) => {
        const actions = (await axios.get(`/grass-calendar/farms/${context.rootState.farmId}/field-actions/years/${context.state.year}`)).data;

        context.commit('setActions', actions);
    },

    updateAction: async(context, payload: GrassCalendarAction) => {
        const response = (await axios.put(`/grass-calendar/farms/${context.rootState.farmId}/fields-actions/${actionId(payload)}`, payload)).data;

        context.commit('updateAction', response);
    },

    saveAction: async(context, payload: Partial<GrassCalendarAction>) => {
        await axios.post(`/grass-calendar/farms/${context.rootState.farmId}/fields-actions`, payload);

        return context.dispatch('fetchActions');
    },

    deleteAction: async(context, payload: GrassCalendarAction) => {
        const type = actionType(payload);
        const id = actionId(payload);

        await axios.delete(`/grass-calendar/farms/${context.rootState.farmId}/fields-actions/${type}/${id}`);

        context.commit('removeAction', payload);
    },

    fetchGroundTypes: async(context) => {
        const response = (await axios.get('/grass-calendar/ground-types')).data;

        context.commit('setGroundTypes', response);
    },

    fetchCropTypes: async(context) => {
        const response = (await axios.get('/grass-calendar/crop-types')).data;

        context.commit('setCropTypes', response);
    },
};

export default actions;
