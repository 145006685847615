import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { SiematicState } from '@/store/modules/siematic/siematicState';
import { SiematicFeedStation } from '@/core/interfaces/SiematicFeedStation';

const actions: ActionTree<SiematicState, RootState> = {
    fetchFeedStations: async(context: ActionContext<SiematicState, RootState>) => {
        const response = await axios.get(context.getters.siematicApiUrl('/feed-stations'));

        context.commit('setFeedStations', response.data);
    },

    updateFeedStation: async(context: ActionContext<SiematicState, RootState>, payload: Partial<SiematicFeedStation>) => {
        await axios.put(context.getters.siematicApiUrl(`/feed-stations/${payload.id}`), payload);

        context.commit('updateFeedStation', payload);
    },
};

export default actions;
