import { RootState } from '@/store/rootState';

export default {
    actions: [],
    barns: {},
    barnsList: [],
    farmId: null,
    farms: {},
    farmsList: [],
    feedTypes: {},
    feedTypesList: [],
    fences: {},
    fencesFeedHeights: {},
    fencesList: [],
    linkedFences: [],
    loadingRefCount: 0,
    locales: {},
    planningInProgress: false,
    planningTriggerDate: new Date(),
    presetRuns: [],
    rations: [],
    showDebug: false,
    userId: null,
    users: {},
    usersList: [],
    vehicleTypeActions: {},
    vehicleTypes: {},
    vehicleTypesList: [],
    vehicles: {},
    vehiclesList: [],
    vehicleStatusses: [],
    planningSettings: null,
    editRation: null,
    editRationDirty: false,
    editRationFenceIds: [],
    editRationFeedTypes: [],
    farmPlanners: [],
    unavailabilities: [],
    runsFilterPreferences: {
        usePlanningStartTime: true,
        vehicleTypeIds: [],
        showRunsWithoutFeeding: false,
    },
} as RootState;
