import { MutationTree } from 'vuex';
import { SiematicState } from '@/store/modules/siematic/siematicState';
import { SiematicFeedStation } from '@/core/interfaces/SiematicFeedStation';

const mutations: MutationTree<SiematicState> = {
    setFeedStations: (state, feedStations) => {
        state.feedStations = feedStations;
    },
    updateFeedStation: (state, payload: Partial<SiematicFeedStation>) => {
        state.feedStations = state.feedStations.map(feedStation => {
            if (feedStation.id === payload.id) {
                return {
                    ...feedStation,
                    ...payload,
                };
            }

            return feedStation;
        });
    },
};

export default mutations;
