import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/components/modules/login/Login.vue';
import store from '@/store';
import Component from 'vue-class-component';
import { LelyAuth } from '@tec/frontend-vue-shared';
import NewPlanning from '@/components/NewPlanning.vue';

Vue.use(VueRouter);

Component.registerHooks([
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            guest: true,
        },
    },
    {
        path: '',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/modules/DashboardModule.vue'),
        children: [
            {
                path: '',
                name: 'dashboard.overview',
                component: () => import(/* webpackChunkName: "dashboard" */ '@/components/modules/dashboard/views/DashboardView.vue'),
            },
        ],
    },
    {
        path: '/overviews',
        name: 'overviews',
        component: { render: h => h('router-view') },
        redirect: () => ({
            name: 'overviews.runs',
        }),
        children: [
            {
                path: 'runs',
                name: 'overviews.runs',
                component: () => import(/* webpackChunkName: "overviews" */ '@/components/modules/overviews/views/RunsView.vue'),
            },
            {
                path: 'fences',
                name: 'overviews.fences',
                component: () => import(/* webpackChunkName: "overviews" */ '@/components/modules/overviews/views/FencesOverviewView.vue'),
            },
            {
                path: 'fences/:fenceId',
                name: 'overviews.fences.item',
                component: () => import(/* webpackChunkName: "overviews" */ '@/components/modules/overviews/views/FencesItemView.vue'),
                props: true,
            },
            {
                path: 'exports',
                name: 'overviews.exports',
                component: () => import(/* webpackChunkName: "overviews" */ '@/components/modules/overviews/views/ExportsView.vue'),
            },
        ],
    },
    {
        path: '/feeding-settings',
        name: 'feeding-settings',
        component: { render: h => h('router-view') },
        redirect: () => ({
            name: 'feeding-settings.rations.overview',
        }),
        children: [
            {
                path: 'rations',
                name: 'feeding-settings.rations.overview',
                component: () => import(/* webpackChunkName: "feeding" */ '@/components/modules/feeding/views/Rations/RationsOverviewView.vue'),
            },
            {
                path: 'rations/:rationId',
                component: () => import(/* webpackChunkName: "feeding" */ '@/components/modules/feeding/views/Rations/RationsItemView.vue'),
                name: 'feeding-settings.rations.item',
                props: true,
            },
            {
                path: 'preset-runs',
                component: { render: h => h('router-view') },
                children: [
                    {
                        name: 'feeding-settings.preset-runs.overview',
                        path: '',
                        component: () => import(/* webpackChunkName: "feeding" */ '@/components/modules/feeding/views/PresetRuns/PresetRunsOverviewView.vue'),
                    },
                    {
                        name: 'feeding-settings.preset-runs.item',
                        path: ':id',
                        component: () => import(/* webpackChunkName: "feeding" */ '@/components/modules/feeding/views/PresetRuns/PresetRunItemView.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'planning-settings',
                name: 'feeding-settings.planning-settings',
                component: () => import(/* webpackChunkName: "feeding" */ '@/components/modules/feeding/views/PlanningSettingsView.vue'),
            },
            {
                path: 'feed-types',
                name: 'feeding-settings.feed-types',
                component: () => import(/* webpackChunkName: "feeding" */ '@/components/modules/feeding/views/FeedTypesView.vue'),
            },
        ],
    },
    {
        path: '/fence-settings',
        name: 'fence-settings',
        component: { render: h => h('router-view') },
        redirect: () => ({
            name: 'fence-settings.overview',
        }),
        children: [
            {
                name: 'fence-settings.overview',
                path: '',
                component: () => import(/* webpackChunkName: "fence-settings" */ '@/components/modules/fences/views/FencesOverviewView.vue'),
            },
            {
                name: 'fence-settings.linked-fences',
                path: 'linked-fences',
                component: () => import(/* webpackChunkName: "fence-settings" */ '@/components/modules/fences/views/FencesLinkedFencesView.vue'),
            },
            {
                name: 'fence-settings.pauzes',
                path: 'pauzes',
                component: () => import(/* webpackChunkName: "fence-settings" */ '@/components/modules/fences/views/FencesPauzesView.vue'),
            },
        ],
    },
    {
        path: '/exos',
        component: { render: h => h('router-view') },
        children: [
            {
                path: '',
                name: 'exos.planning',
                component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/PlanningView.vue'),
            },
            {
                path: 'fields',
                name: 'exos.fields',
                component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/FieldsView.vue'),
            },
            {
                path: 'fields/:fieldId',
                name: 'exos.fields.item',
                component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/FieldsItemView.vue'),
                props: true,
            },
            {
                path: 'runs',
                name: 'exos.runs',
                component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/RunsView.vue'),
            },
            {
                path: 'fertilization',
                name: 'exos.fertilization',
                component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/FertilizationView.vue'),
                children: [
                    {
                        path: 'tanks',
                        name: 'exos.fertilization.tanks',
                        component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/Fertilization/FertilizationTanksView.vue'),
                    },
                    {
                        path: 'fertilization',
                        name: 'exos.fertilization.plan',
                        component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/Fertilization/FertilizationPlanView.vue'),
                    },
                ],
            },
            {
                path: 'knivesets',
                name: 'exos.knivesets',
                component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/KniveSetsView.vue'),
            },
            {
                path: 'meadow-birds',
                name: 'exos.meadow-birds',
                component: () => import(/* webpackChunkName: "exos" */ '@/components/modules/exos/views/MeadowBirdsView.vue'),
            },
        ],
    },
    {
        path: '/drymow',
        component: { render: h => h('router-view') },
        children: [
            {
                path: '',
                name: 'drymow.planning',
                component: () => import(/* webpackChunkName: "drymow" */ '@/components/modules/drymow/views/DrymowPlanningView.vue'),
            },
            {
                path: 'bales',
                name: 'drymow.bales',
                component: () => import(/* webpackChunkName: "drymow" */ '@/components/modules/drymow/views/DrymowBalesView.vue'),
            },
        ],
    },
    {
        path: '/atj',
        component: { render: h => h('router-view') },
        children: [
            {
                path: '',
                name: 'atj.overview',
                component: () => import(/* webpackChunkName: "atj" */ '@/components/modules/atj/views/AtjOverviewView.vue'),
            },
            {
                path: 'planning',
                name: 'atj.planning',
                component: () => import(/* webpackChunkName: "atj" */ '@/components/modules/atj/views/AtjPlanningView.vue'),
            },
        ],
    },
    {
        path: '/afx',
        name: 'afx',
        redirect: () => ({
            name: 'afx.map',
        }),
        component: { render: h => h('router-view') },
        children: [
            {
                path: 'map',
                name: 'afx.map',
                component: () => import(/* webpackChunkName: "afx" */ '@/components/modules/afx/views/AfxMapView.vue'),
            },
            {
                path: 'zones',
                name: 'afx.zones',
                component: () => import(/* webpackChunkName: "afx" */ '@/components/modules/afx/views/AfxZonesView.vue'),
            },
            {
                path: 'runs',
                name: 'afx.runs.overview',
                component: () => import(/* webpackChunkName: "afx" */ '@/components/modules/afx/views/AfxRunsView.vue'),
            },
        ],
    },
    {
        path: '/zeta',
        name: 'zeta',
        component: () => import(/* webpackChunkName: "zeta" */ '@/components/modules/zeta/views/ZetaView.vue'),
        props: route => ({ barnZone: route.query.barnZone, lamp: route.query.lamp }),
    },
    {
        path: '/new-planning',
        component: NewPlanning,
        name: 'new-planning',
    },
    {
        path: '/grass-calendar',
        name: 'grass-calendar',
        component: () => import(/* webpackChunkName: "zeta" */ '@/components/modules/grasscalendar/views/GrassCalendarView.vue'),
    },
    {
        path: '/siematic',
        name: 'siematic',
        component: () => import(/* webpackChunkName: "siematic" */ '@/components/modules/siematic/views/SiematicView.vue'),
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.guest)) {
        if (!LelyAuth.isAuthenticated()) {
            return next();
        }

        return next({ name: 'dashboard.overview' });
    }

    if (LelyAuth.isAuthenticated()) {
        return next();
    }

    return next({ name: 'login' });
});

router.beforeEach((to, from, next) => {
    store.dispatch('notifications/clear')
        .then();

    return next();
});

export default router;
