import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { SiematicState } from '@/store/modules/siematic/siematicState';

const getters: GetterTree<SiematicState, RootState> = {
    siematicApiUrl: (state: SiematicState, getters: any, rootState: RootState) => (url: string): string => `/siematic/farms/${rootState.farmId}${url}`,

    feedStations: state => state.feedStations,
};
export default getters;
