import { ActionContext } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import Barn from '@/core/interfaces/Barn';
import VehicleTypeAction from '@/core/interfaces/VehicleTypeAction';
import LinkedFence from '@/core/interfaces/LinkedFence';
import PresetRun from '@/core/interfaces/PresetRun';
import { LelyAuth, RelayService, Subscription } from '@tec/frontend-vue-shared';
import Ration from '@/core/interfaces/Ration';
import Fence from '@/core/interfaces/Fence';
import PlanningSettings from '@/core/interfaces/PlanningSettings';
import FeedType from '@/core/interfaces/FeedType';
import Farm from '@/core/interfaces/Farm';
import Unavailability from '@/core/interfaces/Unavailability';

let relayService: RelayService | null = null;
let planningSubscription: Subscription<any> | null = null;

function createRelay(): RelayService {
    if (!relayService) {
        if (!process.env.VUE_APP_HMF_WS_URL) {
            throw new Error('VUE_APP_HMF_WS_URL not provided');
        }

        relayService = new RelayService(process.env.VUE_APP_HMF_WS_URL, 'farm_id', 'hmf', () => String(LelyAuth.getAccessToken()));
    }

    return relayService;
}

export const syncFarm = (context: ActionContext<RootState, RootState>): void => {
    context.dispatch('syncFences')
        .then();

    context.dispatch('syncLinkedFences')
        .then();

    context.dispatch('syncPresetRuns')
        .then();

    context.dispatch('syncBarns')
        .then();

    context.dispatch('syncFeedTypes')
        .then();

    context.dispatch('syncVehicleTypes')
        .then();

    context.dispatch('syncVehicles')
        .then();

    context.dispatch('syncRations')
        .then();

    context.dispatch('syncActions')
        .then();

    context.dispatch('exos/sync')
        .then();

    context.dispatch('syncVehicleStatusses')
        .then();

    context.dispatch('syncPlanningUpdate')
        .then();

    context.dispatch('syncPlanningSettings')
        .then();

    context.dispatch('syncFarmPlanners')
        .then();

    context.dispatch('syncUnavailabilities')
        .then();

    context.dispatch('kpis/fetchVehicleStateDurationCategories')
        .then();

    context.dispatch('grasscalendar/fetchCropTypes')
        .then();

    context.dispatch('grasscalendar/fetchGroundTypes')
        .then();

    setInterval(() => context.dispatch('syncVehicleStatusses'), 1000 * 30);
};

export const syncFarms = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get<Farm[]>('web/farms');
    context.commit('setFarms', res.data);
};

export const syncFences = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/fences`);
    context.commit('setFences', res.data);
};

export const syncLinkedFences = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/linked-fences`);
    context.commit('setLinkedFences', res.data);
};

export const removeLinkedFence = async(context: ActionContext<RootState, RootState>, linkedFenceId: number): Promise<void> => {
    context.commit('removeLinkedFence', linkedFenceId);

    await axios.delete(`web/farms/${context.state.farmId}/linked-fences/${linkedFenceId}`);

    return context.dispatch('syncLinkedFences');
};

export const saveLinkedFence = async(context: ActionContext<RootState, RootState>, linkedFence: Partial<LinkedFence>): Promise<void> => {
    context.commit('updateLinkedFence', linkedFence);

    if (linkedFence.id) {
        await axios.put(`web/farms/${context.state.farmId}/linked-fences/${linkedFence.id}`, linkedFence);
    } else {
        await axios.post(`web/farms/${context.state.farmId}/linked-fences`, linkedFence);
    }

    return context.dispatch('syncLinkedFences');
};

export const syncBarns = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/barns`);
    context.commit('setBarns', res.data);
};

export const saveBarn = async(context: ActionContext<RootState, RootState>, barn: Barn): Promise<void> => {
    const res = await axios.put(`web/farms/${context.state.farmId}/barns/${barn.id}`, barn);
    context.commit('updateBarn', res.data);
};

export const syncFeedTypes = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/feed-types`);
    context.commit('setFeedTypes', res.data);
};

export const syncVehicleTypes = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/vehicle-types`);
    context.commit('setVehicleTypes', res.data);
};

export const syncRations = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/rations2`);

    context.commit('setRations', res.data);
};

export const saveRation = async(context: ActionContext<RootState, RootState>, ration: Partial<Ration>): Promise<Ration> => {
    let res;

    if (ration.id) {
        res = await axios.put(`web/farms/${context.state.farmId}/rations2/${ration.id}`, ration);
    } else {
        res = await axios.post(`web/farms/${context.state.farmId}/rations2`, ration);
    }

    context.commit('updateRation', res.data);

    return res.data;
};

export const removeRation = async(context: ActionContext<RootState, RootState>, rationId: number): Promise<void> => {
    context.commit('removeRation', rationId);

    await axios.delete(`web/farms/${context.state.farmId}/rations/${rationId}`);
};

export const saveFence = async(context: ActionContext<RootState, RootState>, fence: Partial<Fence>): Promise<void> => {
    await axios.put(`web/farms/${context.state.farmId}/fences/${fence.id}`, fence);
};

export const syncVehicleTypeActions = async(context: ActionContext<RootState, RootState>, vehicleTypeId: number): Promise<void> => {
    const res = await axios.get<VehicleTypeAction[]>(`web/farms/${context.state.farmId}/vehicle-types/${vehicleTypeId}/actions`);

    context.commit('setVehicleTypeActions', {
        vehicleTypeId,
        actions: res.data.map(vehicleTypeAction => context.getters.actionById(vehicleTypeAction.vehicle_action_id)),
    });
};

export const syncActions = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('web/actions');

    context.commit('setActions', res.data);
};

export const syncVehicles = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/vehicles`);
    context.commit('setVehicles', res.data);
};

export const syncPresetRuns = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/preset-runs`);
    context.commit('setPresetRuns', res.data);
};

export const savePresetRun = async(context: ActionContext<RootState, RootState>, presetRun: Partial<PresetRun>): Promise<PresetRun> => {
    let res;

    if (presetRun.id) {
        res = await axios.put(`web/farms/${context.state.farmId}/preset-runs/${presetRun.id}`, presetRun);
    } else {
        res = await axios.post(`web/farms/${context.state.farmId}/preset-runs`, presetRun);
    }

    context.commit('updatePresetRun', res.data);

    return res.data;
};

export const removePresetRun = async(context: ActionContext<RootState, RootState>, presetRun: number): Promise<void> => {
    context.commit('removePresetRun', presetRun);

    await axios.delete(`web/farms/${context.state.farmId}/preset-runs/${presetRun}`);
};

export const syncVehicleStatusses = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/vehicle-status`);
    context.commit('setVehicleStatusses', res.data);
};

export const syncPlanningSettings = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/planning-settings`);
    context.commit('setPlanningSettings', res.data);
};

export const savePlanningSettings = async(context: ActionContext<RootState, RootState>, payload: PlanningSettings): Promise<void> => {
    const res = await axios.put(`web/farms/${context.state.farmId}/planning-settings`, payload);
    context.commit('setPlanningSettings', res.data);
};

export const syncPlanningUpdate = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    if (planningSubscription) {
        await createRelay().unsubscribe(planningSubscription);
    }

    planningSubscription = await createRelay().subscribe<{ action: 'started' | 'finished' }>(context.state.farmId, '/planning/update');

    planningSubscription.setCallback(data => context.commit('setPlanningInProgress', data.action === 'started'));
};

export const saveFeedType = async(context: ActionContext<RootState, RootState>, feedType: Partial<FeedType>): Promise<FeedType> => {
    let res;

    if (feedType.id) {
        res = await axios.put(`web/farms/${context.state.farmId}/feed-types/${feedType.id}`, feedType);
    } else {
        res = await axios.post(`web/farms/${context.state.farmId}/feed-types`, feedType);
    }

    return res.data;
};

export const deleteFeedType = async(context: ActionContext<RootState, RootState>, feedTypeId: number): Promise<void> => {
    await axios.delete(`web/farms/${context.state.farmId}/feed-types/${feedTypeId}`);

    await context.dispatch('syncFeedTypes');
};

export const syncFarmPlanners = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('web/farm-planner');
    context.commit('setFarmPlanners', res.data);
};

export const syncUnavailabilities = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`web/farms/${context.state.farmId}/unavailabilities`);
    context.commit('setUnavailabilities', res.data);
};

export const deleteUnavailability = async(context: ActionContext<RootState, RootState>, unavailability: number): Promise<void> => {
    await axios.delete(`web/farms/${context.state.farmId}/unavailabilities/${unavailability}`);

    await context.dispatch('syncUnavailabilities');
};

export const saveUnavailability = async(context: ActionContext<RootState, RootState>, unavailability: Partial<Unavailability>): Promise<void> => {
    if (unavailability.id) {
        await axios.put(`web/farms/${context.state.farmId}/unavailabilities/${unavailability.id}`, unavailability);
    } else {
        await axios.post(`web/farms/${context.state.farmId}/unavailabilities`, unavailability);
    }

    await context.dispatch('syncUnavailabilities');
};

export const enableUnavailability = async(context: ActionContext<RootState, RootState>, payload: {
    unavailability: number,
    enable: boolean
}): Promise<void> => {
    await axios.put(`web/farms/${context.state.farmId}/unavailabilities/${payload.unavailability}/enable`, { enable: payload.enable });

    await context.dispatch('syncUnavailabilities');
};
