import { MutationTree } from 'vuex';
import GrassCalendarField from '@/core/interfaces/GrassCalendarField';
import { GrassCalendarState } from '@/store/modules/grasscalendar/grassCalendarState';
import { GrassCalendarAction, GrassCalendarActionType } from '@/core/interfaces/GrassCalendarAction';
import Vue from 'vue';
import GrassCalendarCropType from '@/core/interfaces/GrassCalendarCropType';
import GrassCalendarGroundType from '@/core/interfaces/GrassCalendarGroundType';

export const actionType = (action: GrassCalendarAction): GrassCalendarActionType => {
    if (action.fertilizer_action) {
        return 'fertilize';
    }

    if (action.harvest_action) {
        return 'harvest';
    }

    throw new Error('Unknown action');
};

export const actionId = (action: GrassCalendarAction): number => {
    if (action.fertilizer_action) {
        return action.fertilizer_action.id;
    }

    if (action.harvest_action) {
        return action.harvest_action.id;
    }

    throw new Error('Unknown action');
};

export const actionHash = (action: GrassCalendarAction): string => {
    if (action.fertilizer_action) {
        return `fertilize-${action.fertilizer_action.id}`;
    }

    if (action.harvest_action) {
        return `fertilize-${action.harvest_action.id}`;
    }

    throw new Error('Unknown action');
};

export const actionIdAndType = (hash: string): { id: number; type: GrassCalendarActionType } => {
    const [type, id] = hash.split('-');

    return {
        id: Number(id),
        type: type as GrassCalendarActionType,
    };
};

export const actionEquals = (a: GrassCalendarAction, b: GrassCalendarAction): boolean => actionHash(a) === actionHash(b);

const mutations: MutationTree<GrassCalendarState> = {
    setGrassCalendarFields(state: GrassCalendarState, fields: GrassCalendarField[]): void {
        state.grassCalendarFields = fields;
    },

    updateGrassCalendarField(state: GrassCalendarState, field: GrassCalendarField): void {
        const idx = state.grassCalendarFields.findIndex(a => a.id === field.id);

        if (idx !== -1) {
            Vue.set(state.grassCalendarFields, idx, field);
        } else {
            state.grassCalendarFields.push(field);
        }
    },

    removeGrassCalendarField(state: GrassCalendarState, fieldId: number): void {
        const idx = state.grassCalendarFields.findIndex(a => a.id === fieldId);

        if (idx !== -1) {
            state.grassCalendarFields.splice(idx, 1);
        }
    },

    setYear(state: GrassCalendarState, year: number): void {
        state.year = year;
    },

    setActions(state: GrassCalendarState, actions: GrassCalendarAction[]): void {
        state.actions = actions;
    },

    setCropTypes(state: GrassCalendarState, cropTypes: GrassCalendarCropType[]): void {
        state.cropTypes = cropTypes;
    },

    setGroundTypes(state: GrassCalendarState, groundTypes: GrassCalendarGroundType[]): void {
        state.groundTypes = groundTypes;
    },

    updateAction(state: GrassCalendarState, action: GrassCalendarAction): void {
        const idx = state.actions.findIndex(a => actionEquals(a, action));

        if (idx !== -1) {
            Vue.set(state.actions, idx, action);
        } else {
            state.actions.push(action);
        }
    },

    removeAction(state: GrassCalendarState, action: GrassCalendarAction): void {
        const idx = state.actions.findIndex(a => actionEquals(a, action));

        if (idx !== -1) {
            state.actions.splice(idx, 1);
        }
    },
};

export default mutations;
