import { MutationTree } from 'vuex';
import { ExosState } from '@/store/modules/exos/exosState';
import ExosField from '@/components/modules/exos/lib/types/ExosField';
import Planning from '@/components/modules/exos/lib/types/Planning';
import Bird from '@/components/modules/exos/lib/types/Bird';
import BirdNest from '@/components/modules/exos/lib/types/BirdNest';
import Mowplan from '@/components/modules/exos/lib/types/Mowplan';
import ActiveRun from '@/components/modules/exos/lib/types/ActiveRun';
import FertilizerTank from '@/core/interfaces/FertilizerTank';

const mutations: MutationTree<ExosState> = {
    setFields: (state: ExosState, fields: ExosField[]) => {
        state.fields = fields.reduce((acc: Record<number, ExosField>, item: ExosField) => {
            acc[item.id] = { ...acc[item.id], ...item };

            return acc;
        }, {});

        state.fieldsList = fields.map(item => item.id);
    },
    setActiveRuns: (state: ExosState, runs: ActiveRun[]) => {
        state.activeRuns = runs;
    },
    setPlanning: (state: ExosState, planning: Planning[]) => {
        state.planning = planning.reduce((acc: any, item) => {
            acc[item.id] = { ...acc[item.id], ...item };

            return acc;
        }, {});

        state.planningList = planning.map(item => item.id);
    },
    setBirds: (state: ExosState, birds: Bird[]) => {
        state.birds = birds.reduce((acc: any, item) => {
            acc[item.id] = { ...acc[item.id], ...item };

            return acc;
        }, {});

        state.birdsList = birds.map(item => item.id);
    },
    setBirdNests: (state: ExosState, birdNests: BirdNest[]) => {
        state.birdNests = birdNests;
    },
    setMowplans: (state: ExosState, mowplans: Mowplan[]) => {
        state.mowplans = mowplans.reduce((acc: any, item) => {
            acc[item.id] = { ...acc[item.id], ...item };

            return acc;
        }, {});

        state.mowplansList = mowplans.map(item => item.id);
    },
    setFertilizerTanks: (state: ExosState, fertilizerTanks: FertilizerTank[]) => {
        state.fertilizerTanks = fertilizerTanks;
    },
};

export default mutations;
