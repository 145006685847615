import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { GrassCalendarState } from '@/store/modules/grasscalendar/grassCalendarState';
import GrassCalendarField from '@/core/interfaces/GrassCalendarField';
import { FieldInterface } from '@/core/interfaces/FieldInterface';
import GrassCalendarGroundType from '@/core/interfaces/GrassCalendarGroundType';
import GrassCalendarCropType from '@/core/interfaces/GrassCalendarCropType';
import { GrassCalendarAction, GrassCalendarActionType } from '@/core/interfaces/GrassCalendarAction';
import { actionHash } from '@/store/modules/grasscalendar/mutations';

const getters: GetterTree<GrassCalendarState, RootState> = {
    grassCalendarFields: (state: GrassCalendarState): GrassCalendarField[] => state.grassCalendarFields.sort((a, b) => a
        .name.localeCompare(b.name)),
    grassCalendarFieldById: (state: GrassCalendarState) => (id: number): GrassCalendarField | null => state
        .grassCalendarFields.find((field) => field.id === id) || null,
    fieldsCombined: (state: GrassCalendarState, getters: any, rootState: any, rootGetters: any): FieldInterface[] => [
        ...state.grassCalendarFields,
        ...rootGetters['exos/fieldsSet'],
    ],
    actions: (state: GrassCalendarState): GrassCalendarAction[] => state.actions,
    year: (state: GrassCalendarState): number => state.year,
    actionByHash: (state: GrassCalendarState) => (hash: string): GrassCalendarAction | undefined => state.actions.find(action => actionHash(action) === hash),
    cropTypes: (state: GrassCalendarState): GrassCalendarCropType[] => state.cropTypes,
    groundTypes: (state: GrassCalendarState): GrassCalendarGroundType[] => state.groundTypes,
    groundTypeById: (state: GrassCalendarState) => (id: number): GrassCalendarGroundType | undefined => state
        .groundTypes.find((groundType) => groundType.id === id),
    cropTypeById: (state: GrassCalendarState) => (id: number): GrassCalendarCropType | undefined => state
        .cropTypes.find((cropType) => cropType.id === id),
};

export default getters;
