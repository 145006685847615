
import { Component, Watch } from 'vue-property-decorator';
import { Calendar } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import Vehicle from '@/core/interfaces/Vehicle';
import axios from 'axios';
import { mixins } from 'vue-class-component';
import UsesFarmId from '@/components/shared/mixins/UsesFarmId';
import {
    addMinutes, differenceInMinutes, endOfTomorrow, startOfTomorrow,
} from 'date-fns';
import Run from '@/core/interfaces/Run';
import { Getter, Mutation, State } from 'vuex-class';
import PlanningSettings from '@/core/interfaces/PlanningSettings';
import interactionPlugin from '@fullcalendar/interaction';
import UsesVehicleTypeActionIcon from '@/components/shared/mixins/UsesVehicleTypeActionIcon';
import FenceVisitAction from '@/core/enums/FenceVisitAction';
import VehicleAction from '@/core/interfaces/VehicleAction';
import Pauzes from '@/components/shared/Pauzes/Pauzes.vue';
import Unavailability from '@/core/interfaces/Unavailability';
import InterruptionsTimeline from '@/components/shared/InterruptionsTimeline.vue';
import DashboardVehiclesStatusRunTooltip from '@/components/modules/dashboard/components/DashboardVehiclesStatusRunTooltip.vue';
import RunsTable from '@/components/modules/overviews/components/RunsTable.vue';
import Fence from '@/core/interfaces/Fence';
import OverviewsPage from '@/components/modules/overviews/OverviewsPage.vue';
import VehicleType from '@/core/interfaces/VehicleType';
import RunsFilters, { RunsFiltersData } from '@/components/modules/overviews/components/RunsFilters.vue';
import UsesBeforeWindowUnload from '@/components/shared/mixins/UsesBeforeWindowUnload';
import UsesPeriodWithPlanningStartTime from '@/components/shared/mixins/UsesPeriodWithPlanningStartTime';
import UsesVehicleTypeColors from '@/components/shared/mixins/UsesVehicleTypeColors';

type OverrideRun = {
    order_uuid: string;
    started_at: string;
};

type ExpandedRun = (Run & { modified: boolean, original_started_at: string, original_estimated_completed_at: string });

@Component({
    components: {
        RunsFilters,
        OverviewsPage,
        RunsTable,
        DashboardVehiclesStatusRunTooltip,
        InterruptionsTimeline,
        Pauzes,
    },
})
export default class NewPlanning extends mixins(
    UsesFarmId,
    UsesVehicleTypeActionIcon,
    UsesBeforeWindowUnload,
    UsesPeriodWithPlanningStartTime,
    UsesVehicleTypeColors,
) {
    @State('planningSettings')
    planningSettings!: PlanningSettings | null;

    @Getter('actionById')
    actionById!: (id: number) => VehicleAction;

    @Getter('actionByName')
    actionByName!: (name: string) => VehicleAction;

    @Getter('vehicleById')
    vehicleById!: (id: number) => Vehicle;

    @Getter('fencesSet')
    fences!: Fence[];

    @Getter('vehicleTypesWithActions')
    vehicleTypesWithActions!: VehicleType[];

    @Getter('unavailabilities')
    unavailabilities!: Unavailability[];

    @State('planningInProgress')
    updatingPlanning!: boolean;

    @Mutation('setPlanningInProgress')
    setPlanningInProgress!: (val: boolean) => void;

    loading = true;

    runs: Run[] = [];

    hoveringRunUuid: string | null = null;

    highlightedRun: Run | null = null;

    draggingDiffInMinutes: number | null = null;

    overrideRuns: OverrideRun[] = [];

    filters: RunsFiltersData = {
        vehicleTypeIds: [],
        showRunsWithoutFeeding: false,
        usePlanningStartTime: true,
    };

    async mounted(): Promise<void> {
        const preferences = this.$store.getters.runsFilterPreferences;

        this.filters.showRunsWithoutFeeding = preferences.showRunsWithoutFeeding;
        this.filters.usePlanningStartTime = preferences.usePlanningStartTime;
        this.filters.vehicleTypeIds = preferences.vehicleTypeIds.length
            ? preferences.vehicleTypeIds
            : this.vehicleTypesWithActionsAndRuns.map(v => v.id);

        const slotMinTime = this.planningSettings?.planningscycle_starttime || '00:00';
        const slotMaxTime = [undefined, '00:00'].includes(this.planningSettings?.planningscycle_endtime)
            ? '23:59'
            : this.planningSettings?.planningscycle_endtime;

        this.$options.calendar = new Calendar(this.$refs.calendar as HTMLElement, {
            plugins: [resourceTimelinePlugin, interactionPlugin],
            initialView: 'resourceTimeline',
            initialDate: startOfTomorrow(),
            headerToolbar: false,
            height: 'auto',
            resourceAreaWidth: '120px',
            slotMinTime,
            slotMaxTime,
            slotDuration: '00:15',
            slotMinWidth: '10',
            eventMinWidth: '0',
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            eventStartEditable: true,
            eventResourceEditable: false,
            eventDurationEditable: false,
            slotLabelFormat: {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            },
            slotLabelInterval: '01:00',
            resources: (fetchInfo, callback) => callback(this.resources),
            resourceAreaHeaderContent: this.$t('general.vehicles'),
            resourceOrder: 'title',
            editable: true,
            eventColor: 'transparent',
            events: (fetchInfo, callback) => callback(this.events),
            eventMouseEnter: (arg) => {
                const tooltipTarget = this.$refs['tooltip-target'] as HTMLDivElement;

                if (!tooltipTarget) {
                    return;
                }

                const rect = arg.el.getBoundingClientRect();
                const x = rect.x - this.$refs['calendar-wrapper'].getBoundingClientRect().left;
                const y = rect.y - this.$refs['calendar-wrapper'].getBoundingClientRect().top;

                tooltipTarget.style.transform = `translate3d(${x}px, ${y}px, 0)`;
                tooltipTarget.style.width = `${rect.width}px`;
                tooltipTarget.style.height = `${rect.height}px`;
                tooltipTarget.style.marginLeft = '';

                this.hoveringRunUuid = arg.event.id;
            },
            eventMouseLeave: () => {
                this.hoveringRunUuid = null;
            },
            eventAllow: (info, event) => {
                const draggingStart = info.start;
                const eventStart = event.start;

                this.draggingDiffInMinutes = ((draggingStart.getTime() - eventStart.getTime()) / 1000 / 60);

                const pixelsPerMinute = (this.$refs.calendar.querySelector('.fc-timeline-slot-minor').clientWidth + 1) / 15;

                const tooltipTarget = this.$refs['tooltip-target'] as HTMLDivElement;
                tooltipTarget.style.marginLeft = `${this.draggingDiffInMinutes * pixelsPerMinute}px`;

                return true;
            },
            eventDragStop: (info) => {
                this.overrideRuns = this.overrideRuns.filter(run => run.order_uuid !== info.event.id);

                if (this.draggingDiffInMinutes) {
                    this.overrideRuns.push({
                        order_uuid: info.event.id,
                        started_at: addMinutes(info.event.start, this.draggingDiffInMinutes).toISOString(),
                    });
                }

                this.draggingDiffInMinutes = null;
            },
            eventClassNames: (arg) => {
                const classNames = ['fc-event'];

                if (this.modifiedEvents.has(arg.event.id)) {
                    classNames.push('fc-event-modified');
                }

                if (this.highlightedRun?.uuid === arg.event.id) {
                    classNames.push('fc-event-highlighted');
                }

                if (this.highlightedRun && this.highlightedRun?.uuid !== arg.event.id) {
                    classNames.push('fc-event-not-highlighted');
                }

                return classNames;
            },
        });

        this.$options.calendar.render();
    }

    get events(): any[] {
        return this.runsExpandedWithOverrideAndOriginal
            .filter(run => run.fence_visits.length)
            .filter(run => this.filters.vehicleTypeIds.includes(this.vehicleById(run.vehicle_id)?.vehicle_type_id))
            .map(run => {
                const start = new Date(run.started_at);
                const end = new Date(run.estimated_completed_at);

                return {
                    id: run.uuid,
                    resourceId: run.vehicle_id,
                    start,
                    end,
                    backgroundColor: this.vehicleTypeColors[this.vehicleById(run.vehicle_id).vehicle_type_id].fill,
                    borderColor: this.vehicleTypeColors[this.vehicleById(run.vehicle_id).vehicle_type_id].stroke,
                };
            });
    }

    @Watch('filteredRuns')
    updateRuns(): void {
        if (!this.$options.calendar) {
            return;
        }

        this.calendar.refetchEvents();
    }

    @Watch('farmId')
    @Watch('period')
    async fetchRuns(): Promise<Run[]> {
        this.loading = true;

        const filters = new URLSearchParams({
            dateFrom: this.period.start.toISOString(),
            dateUntil: this.period.end.toISOString(),
        }).toString();

        const url = `web/farms/${this.farmId}/scheduled-runs?${filters}`;
        this.runs = (await axios.get(url)).data || [];

        this.loading = false;
    }

    get resources(): { id: string, title: string }[] {
        return this.vehicles
            .filter(vehicle => this.filters.vehicleTypeIds.includes(vehicle.vehicle_type_id))
            .map(vehicle => ({
                id: String(vehicle.id),
                title: vehicle.name,
            }));
    }

    @Watch('resources')
    onUpdateResources(): void {
        if (!this.$options.calendar) {
            return;
        }

        this.calendar.refetchResources();
    }

    get vehicles(): Vehicle[] {
        return this.$store.getters.vehiclesSet.sort((a, b) => a.name.localeCompare(b.name));
    }

    get period(): { start: Date, end: Date } {
        const period = {
            start: startOfTomorrow(),
            end: endOfTomorrow(),
        };

        if (this.filters.usePlanningStartTime) {
            return this.getPeriodWithPlanningStartTime(period);
        }

        return period;
    }

    get hoveredIndex(): number | null {
        if (!this.hoveringRun) {
            return null;
        }

        return this.runsExpandedWithOverrideAndOriginal.findIndex(run => run.uuid === this.hoveringRunUuid);
    }

    get hoveringRun(): ExpandedRun | null {
        if (!this.hoveringRunUuid) {
            return null;
        }

        return this.runsExpandedWithOverrideAndOriginal.find(run => run.uuid === this.hoveringRunUuid);
    }

    get draggingStart(): Date | null {
        if (!this.hoveringRun || !this.draggingDiffInMinutes) {
            return null;
        }

        return addMinutes(new Date(this.hoveringRun.started_at), this.draggingDiffInMinutes);
    }

    get draggingEnd(): Date | null {
        if (!this.hoveringRun || !this.draggingDiffInMinutes) {
            return null;
        }

        return addMinutes(new Date(this.hoveringRun.estimated_completed_at), this.draggingDiffInMinutes);
    }

    get filteredRuns(): Run[] {
        return this.runs
            .sort((a, b) => (a.started_at < b.started_at ? -1 : 1))
            .filter(run => this.filters.vehicleTypeIds.includes(this.vehicleById(run.vehicle_id)?.vehicle_type_id))
            .filter(run => this.filters.showRunsWithoutFeeding || run.fence_visits.some(fenceVisit => fenceVisit.action_id === this.feedActionId));
    }

    get runsExpandedWithOverrideAndOriginal(): ExpandedRun[] {
        return this.filteredRuns.map(run => {
            const overrideRun = this.overrideRuns.find(overrideRun => overrideRun.order_uuid === run.uuid);
            const diff = overrideRun ? differenceInMinutes(new Date(overrideRun.started_at), new Date(run.started_at)) : 0;

            return {
                ...run,
                started_at: overrideRun ? overrideRun.started_at : run.started_at,
                estimated_completed_at: addMinutes(new Date(run.estimated_completed_at), diff).toISOString(),
                original_started_at: run.started_at,
                original_estimated_completed_at: run.estimated_completed_at,
                modified: diff !== 0,
            };
        }).sort((a, b) => (a.started_at < b.started_at ? -1 : 1));
    }

    get filteredUnavailabilities(): Unavailability[] {
        return this.unavailabilities
            .filter(unavailability => this.filters.vehicleTypeIds.includes(unavailability.vehicle_type_id));
    }

    get feedActionId(): number {
        return this.actionByName(FenceVisitAction.FEED)?.id;
    }

    @Watch('highlightedRun')
    highlightRun(): void {
        this.calendar.render();
    }

    get modifiedEvents(): string[] {
        return new Set(this.overrideRuns
            .filter(overrideRun => new Date(overrideRun.started_at).getTime()
                !== new Date(this.runs.find(run => run.uuid === overrideRun.order_uuid)?.started_at).getTime())
            .map(run => run.order_uuid));
    }

    get calendar(): Calendar {
        return this.$options.calendar;
    }

    async save(): Promise<void> {
        this.loading = true;

        const data = this.runs.map(run => ({
            order_uuid: run.uuid,
            started_at: this.overrideRuns.find(overrideRun => overrideRun.order_uuid === run.uuid)?.started_at || run.started_at,
        }));

        try {
            await axios.put(`web/farms/${this.farmId}/override-runs`, data);
        } catch (err) {
            console.error(err);
        }

        this.loading = false;
    }

    async calculatePlanning(): Promise<void> {
        this.setPlanningInProgress(true);

        this.runs = [];
        this.overrideRuns = [];

        await axios.post(`planning/model/${this.farmId}/rebuild`, {});
    }

    @Watch('updatingPlanning')
    async planningCalculated(): Promise<void> {
        if (this.updatingPlanning) {
            return;
        }

        await this.fetchRuns();

        this.reset();
    }

    reset(): void {
        this.overrideRuns = [];

        this.calendar.refetchEvents();
    }

    get formIsDirty(): boolean {
        return this.modifiedEvents.size > 0;
    }

    @Watch('filters')
    savePreferences(): void {
        this.$store.commit('setRunsFilterPreferences', {
            vehicleTypeIds: this.filters.vehicleTypeIds,
            usePlanningStartTime: this.filters.usePlanningStartTime,
            showRunsWithoutFeeding: this.filters.showRunsWithoutFeeding,
        });
    }

    get vehicleTypesWithActionsAndRuns(): VehicleType {
        return this.vehicleTypesWithActions.filter(vehicleType => this.runs
            .some(run => this.vehicleById(run.vehicle_id).vehicle_type_id === vehicleType.id));
    }
}

