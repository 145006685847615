import state from '@/store/modules/grasscalendar/state';
import getters from '@/store/modules/grasscalendar/getters';
import mutations from '@/store/modules/grasscalendar/mutations';
import actions from '@/store/modules/grasscalendar/actions';
import { Module } from 'vuex';
import { GrassCalendarState } from '@/store/modules/grasscalendar/grassCalendarState';
import { RootState } from '@/store/rootState';

const grasscalendar: Module<GrassCalendarState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default grasscalendar;
