
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Module } from '@/core/enums/Module';

@Component({})
export default class LelyNavigationModuleSwitcher extends Vue {
    @Prop()
    activeModule!: string;

    @Getter('enabledModules')
    enabledModules!: Module[];
}

